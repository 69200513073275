.react-tel-input {
    font-family: inherit !important;

    input {
        width: 100% !important;
        font-family: inherit;
        border: none;
        color: inherit;
        padding: 8px;
        font-weight: normal;
        border: none !important;
        border-radius: 50px !important;
        color: grey !important;
    }

    .invalid-number {
        background-color: rgba(255, 64, 64, 0.1) !important;
    }

    .flag-dropdown {
        border: none !important;
        background-color: transparent !important;
        border-right: 1px solid #cacaca;

        .selected-flag {

            &:hover,
            &:focus {
                background-color: transparent !important;
            }
        }
    }

    .open {
        .selected-flag {
            background-color: transparent !important;
        }
    }
}

.form-field-error {
    :nth-child(2) {
        border-color: #FF4040 !important;
    }
}