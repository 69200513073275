.background-gradient {
  background: #948e99;
  background: -webkit-linear-gradient(to right, #2e1437, #948e99);
  background: linear-gradient(to right, #2e1437, #948e99);
}

html,
body {
  margin: 0;
  padding: 0;
}